<template>
  <div>
   
    <v-card elevation="2">
      <v-container py-5>
        <v-row class="d-flex justify-center">
          <v-col lg="3">
            <v-text-field
              label="Placa do Veículo"
              v-model="placas"
              outlined
              dense
              hide-details="auto"
              :error-messages="placaErroMensagem"
            />
          </v-col>
          <v-col lg="3">
            <autocomplete-areas v-model="area" />
          </v-col>
          <v-col lg="5" class="datas-container">
            <date-picker :model-config="modelConfig" v-model="range" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="datas-input d-flex justify-center align-center">
                  <input
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                    class="text-center calendario-input mx-3"
                    :class="{
                      'input-white': $vuetify.theme.dark,
                    }"
                  />
                  <v-icon class="icone"> mdi-arrow-right </v-icon>
                  <input
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    class="text-center calendario-input mx-3"
                    :class="{
                      'input-white': $vuetify.theme.dark,
                    }"
                  />
                </div>
              </template>
            </date-picker>
          </v-col>
          <v-col lg="2">
            <btn-buscar
              class="text-center"
              :carregando="$store.state.layout.loading"
              :temPermissao="true"
              @click="carregarRelatorios()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      class="mx-5 my-4"
      v-for="(item, referencia) in container"
      :key="referencia"
    >
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :headers="cabecalho"
          :items="items"
          mobile-breakpoint="1000"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import relatorios from "@/api/relatorios";
import formatarData from "@/utils/formatar-data-input";
export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      totalRegistros: 0,
      area: null,
      placaErroMensagem: null,
      container: null,

      placas: null,
      cabecalho: [
        { text: "Nro.Ticket", align: "center", value: "ticket", divider: true },
        { text: "Placa", align: "center", value: "placa", divider: true },
        { text: "Box", align: "center", value: "box_descricao", divider: true },
        { text: "Area", align: "center", value: "area_nome", divider: true },
        {
          text: "H.Chegada",
          align: "center",
          value: "hora_chegada",
          divider: true,
        },
        {
          text: "H.Saida",
          align: "center",
          value: "hora_saida",
          divider: true,
        },
        {
          text: "Data.Pagamento",
          align: "center",
          value: "data_pagamento",
          divider: true,
        },
        { text: "Valor", align: "end", value: "valor" },
      ],
      items: [],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      range: {
        start: moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    async carregarRelatorios() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const datas = {
          data_inicial: this.range.start,
          data_final: this.range.end,
          area_id: this.area,
          placa: this.placas,
        };
        const dados = datas;
        const resposta = await relatorios.relatorioMovimentacaoVeiculo(dados);

        const total = [
          {
            ticket: "Total",
            valor: 0,
          },
        ];

        resposta.data.data.map((item) => {
          if (item.hora_saida === null) {
            item.hora_saida = "";
          } else {
            item.hora_saida = formatarData.ptBrComHorario(item.hora_saida);
          }

          if (item.data_pagamento === null) {
            item.data_pagamento = "";
          } else {
            item.data_pagamento = formatarData.ptBrComHorario(
              item.data_pagamento
            );
          }

          if (item.hora_chegada === null) {
            item.hora_chegada = "";
          } else {
            item.hora_chegada = formatarData.ptBrComHorario(item.hora_chegada);
          }

          if (item.valor) {
            item.valor = parseFloat(item.valor).toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            });
          } else if (item.valor === null) {
            item.valor = "0,00";
          }

          total.map((item2) => {
            item2.valor = item2.valor + parseFloat(item.valor);
          });
        });

        this.items = resposta.data.data;
        total.map((item) => {
          item.valor = item.valor.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          });
          this.items.push(item);
        });

        this.container = resposta.data;
      } catch (e) {
        this.placaErroMensagem = e.response.data.erros.placa;
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
        
      }
    },
  },
};
</script>

<style scoped>
.teste {
  position: absolute;
  top: 45px;
}
.titulo-area {
  font-weight: bold;
}
.col {
  flex-basis: 200px;
}
.calendario-input {
  height: 40px;
  border-radius: 5px;
  border: 2px solid black;
}
@media (max-width: 576px) {
  .datas-input {
    display: block !important;
  }
  .datas-container {
    text-align: center;
  }
  .calendario-input {
    margin: 5px 0;
  }
  .icone {
    display: none;
  }
}
</style>
